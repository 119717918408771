export const getSubscriptionPrice = (level) => {
  switch (level) {
    case "Small":
      return 500;
    case "Medium":
      return 1500;
    case "Large":
      return 3000;
    default:
      throw new Error(
        `The provided subscription level ${level} is not defined.`
      );
  }
};

export const calculateFromTimePeriod = (baseCost, timePeriod) => {
  let multiplier;

  switch (timePeriod) {
    case "monthly":
      multiplier = 1;
      break;
    case "annually":
      multiplier = 0.85;
      break;
    default:
      throw new Error(
        `The provided payment method ${timePeriod} is not defined.`
      );
  }

  return baseCost * multiplier;
};

export const formatPrice = (price) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(price);
};
