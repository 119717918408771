import { collection, addDoc, setDoc, updateDoc, doc, getDocs, query, where } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { db } from "../firebase";

const useCreateCompany = () => {

  const addCompany = async (companyData, paymentMethod) => {
    const companyToAdd = {};

    companyToAdd.name = companyData.name.trim();
    companyToAdd.location = companyData.location.trim();
    companyToAdd.website = companyData.website.trim();

    if (companyData.contactName) {
      companyToAdd.contactName = companyData.contactName;
    } else {
      companyToAdd.contactName = '';
    }

    if (companyData.contactEmail) {
      companyToAdd.contactEmail = companyData.contactEmail.toLowerCase();
    } else {
      companyToAdd.contactEmail = '';
    }

    if (companyData.details) {
      companyToAdd.additionalDetails = companyData.details;
    } else {
      companyToAdd.additionalDetails = '';
    }

    companyToAdd.referredByNonprofit = companyData.nonprofit;
    companyToAdd.referredByNonprofitContactName = companyData.nonprofitContactName;
    companyToAdd.referredByNonprofitContactEmail = companyData.nonprofitContactEmail.toLowerCase();

    companyToAdd.referredOn = new Date();
    companyToAdd.source = 'Website Referral Form';


    // create referrral document
    const docSnapshot = await addDoc(collection(db, "referrals"), companyToAdd);
    const referralId = docSnapshot.id;
    const updatedDoc = doc(db, "referrals", referralId);
    await updateDoc(updatedDoc, { referralId: referralId });

    // setCompany(docSnapshot);
    return docSnapshot;
  };

  return addCompany;
};

export default useCreateCompany;
