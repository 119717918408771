import {
  getSubscriptionPrice,
  formatPrice,
} from "../../helpers/getSubscriptionPrices";
import { PROD_URL } from "../../constants";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

/*
returns Grateful logo as an SVG; the PDF generator requires this as the raw SVG instead of providing it as an asset file
*/
function gratefulLogo() {
  return `<svg width="874" height="170" viewBox="0 0 874 170" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M80.0802 54.7027L54.334 28.7195L28.6408 54.456L54.4381 80.2963L59.7518 75.065L80.0802 54.7027ZM46.0623 105.516C46.0337 105.488 46.0052 105.46 45.9768 105.432L3.47467 62.8587C-1.15822 58.2181 -1.15822 50.6942 3.47467 46.0535L45.9768 3.48057C50.622 -1.17236 58.1577 -1.1582 62.7855 3.51215L96.8571 37.8976L131.217 3.48046C135.862 -1.17247 143.398 -1.15831 148.026 3.51204L190.733 46.6131C195.365 51.2873 195.318 58.8429 190.629 63.4591L149.291 104.155C148.929 104.651 148.524 105.124 148.076 105.571L105.353 148.144C100.716 152.764 93.2218 152.755 88.5974 148.122L46.0952 105.55C46.0842 105.539 46.0733 105.527 46.0623 105.516ZM76.4778 91.9216L71.2614 97.1468L97.0078 122.936L122.888 97.1468L97.1861 71.5348L76.4778 91.9216ZM165.495 54.8795L139.574 28.7197L113.881 54.4561L139.678 80.2965L165.495 54.8795Z" fill="#FF264A"/>
  <path d="M289.863 45.2317V50.3192C284.776 43.233 276.781 38.1455 264.062 38.1455C235.899 38.1455 219.729 59.0406 219.729 83.2062C219.729 107.372 235.899 128.267 264.062 128.267C276.781 128.267 284.776 123.543 289.863 117.002V121.181C289.863 135.535 284.412 145.346 266.424 145.346C257.885 145.346 251.162 142.621 247.346 139.169C245.711 137.897 243.531 137.715 241.714 138.805L227.723 146.982C224.998 148.617 224.634 152.432 226.815 154.613C235.354 163.516 250.617 169.149 267.878 169.149C300.402 169.149 319.48 150.615 319.48 124.27V46.867C319.48 43.233 316.573 40.3259 312.939 40.3259H294.769C292.044 40.3259 289.863 42.5062 289.863 45.2317ZM269.332 102.829C256.976 102.829 249.527 94.4714 249.527 83.2062C249.527 71.7593 256.976 63.4013 269.332 63.4013C280.415 63.4013 288.955 70.8509 288.955 83.2062C288.955 95.3799 280.415 102.829 269.332 102.829Z" fill="#1E1C20"/>
  <path d="M364.131 54.3165V46.867C364.131 43.233 361.224 40.3259 357.772 40.3259H341.056C337.603 40.3259 334.696 43.233 334.696 46.867V125.905C334.696 129.539 337.603 132.446 341.056 132.446H357.772C361.224 132.446 364.131 129.539 364.131 125.905V96.1066C364.131 81.5709 366.675 67.762 388.115 69.2156C390.84 69.3973 393.384 67.2169 393.384 64.3098V43.4147C393.384 40.3259 390.477 38.1455 387.57 38.6906C378.485 40.3259 369.582 45.7768 364.131 54.3165Z" fill="#1E1C20"/>
  <path d="M465.953 45.2317V49.4107C460.502 42.6879 452.326 38.1455 439.97 38.1455C411.262 38.1455 395.818 62.3111 395.818 86.4767C395.818 110.461 411.262 134.626 439.97 134.626C452.326 134.626 460.502 130.084 465.953 123.361V127.54C465.953 130.266 468.133 132.446 470.859 132.446H489.028C492.662 132.446 495.569 129.539 495.569 125.905V46.867C495.569 43.233 492.662 40.3259 489.028 40.3259H470.859C468.133 40.3259 465.953 42.5062 465.953 45.2317ZM445.24 106.1C433.066 106.1 425.616 97.7419 425.616 86.4767C425.616 75.0299 433.066 66.6718 445.24 66.6718C456.323 66.6718 465.044 74.1214 465.044 86.4767C465.044 98.6504 456.323 106.1 445.24 106.1Z" fill="#1E1C20"/>
  <path d="M562.65 58.1321V46.867C562.65 43.233 559.743 40.3259 556.109 40.3259H544.481V27.4255C544.481 23.9732 541.573 21.0661 538.121 21.0661H521.405C517.953 21.0661 515.046 23.9732 515.046 27.4255V40.3259H508.505C504.871 40.3259 501.964 43.233 501.964 46.867V58.1321C501.964 61.766 504.871 64.6732 508.505 64.6732H515.046V96.6517C515.046 114.821 521.587 134.081 549.932 134.081C553.565 134.081 556.291 133.718 558.653 133.354C561.015 132.991 562.65 130.992 562.65 128.63V113.004C562.65 110.279 560.47 108.099 557.744 108.28C557.563 108.28 557.381 108.28 557.199 108.28C547.933 108.28 544.481 103.374 544.481 93.7446V64.6732H556.109C559.743 64.6732 562.65 61.766 562.65 58.1321Z" fill="#1E1C20"/>
  <path d="M617.197 38.1455C590.851 38.1455 569.774 59.2223 569.774 86.4767C569.774 115.003 590.306 134.626 621.558 134.626C637.002 134.626 649.721 128.812 657.715 119.909C659.532 117.547 658.987 114.095 656.443 112.459L643.543 104.828C641.726 103.738 639.546 103.92 637.91 105.373C634.458 108.28 628.826 111.006 621.558 111.006C610.293 111.006 601.389 106.1 598.301 95.3799H658.805C662.258 95.3799 665.165 92.4727 665.165 88.8388C665.71 54.4982 642.998 38.1455 617.197 38.1455ZM598.482 77.5736C601.208 67.3986 608.839 61.766 618.287 61.766C628.462 61.766 635.912 66.6718 638.274 77.5736H598.482Z" fill="#1E1C20"/>
  <path d="M724.252 25.2451C724.434 25.2451 724.616 25.2451 724.798 25.2451C727.341 25.4268 729.703 23.2465 729.703 20.521V6.167C729.703 3.62325 727.886 1.6246 725.524 1.2612C723.162 0.897811 720.437 0.534424 716.985 0.534424C687.732 0.534424 682.099 23.2464 682.099 40.3259H677.193C673.559 40.3259 670.652 43.233 670.652 46.867V58.1321C670.652 61.766 673.559 64.6732 677.193 64.6732H682.099V125.905C682.099 129.539 685.006 132.446 688.458 132.446H704.993C708.627 132.446 711.534 129.539 711.534 125.905V64.6732H723.162C726.796 64.6732 729.703 61.766 729.703 58.1321V46.867C729.703 43.233 726.796 40.3259 723.162 40.3259H711.534C711.534 30.696 714.441 25.2451 724.252 25.2451Z" fill="#1E1C20"/>
  <path d="M805.72 40.3259C802.268 40.3259 799.361 43.233 799.361 46.867V89.929C799.361 100.286 793.183 105.918 784.643 105.918C776.467 105.918 769.926 100.649 769.926 89.929V46.867C769.926 43.233 767.019 40.3259 763.385 40.3259H746.85C743.216 40.3259 740.309 43.233 740.309 46.867V97.9236C740.309 116.638 751.211 134.626 774.105 134.626C786.097 134.626 794.091 130.084 799.361 124.088V127.54C799.361 130.266 801.541 132.446 804.085 132.446H822.254C825.888 132.446 828.795 129.539 828.795 125.905V46.867C828.795 43.233 825.888 40.3259 822.254 40.3259H805.72Z" fill="#1E1C20"/>
  <path d="M843.967 11.7996V125.905C843.967 129.539 846.874 132.446 850.326 132.446H867.042C870.494 132.446 873.401 129.539 873.401 125.905V11.7996C873.401 8.16566 870.494 5.25852 867.042 5.25852H850.326C846.874 5.25852 843.967 8.16566 843.967 11.7996Z" fill="#1E1C20"/>
  </svg>`;
}

/*
create and download PDF proposal document using pdfmake package

source: https://www.npmjs.com/package/pdfmake
documentation: https://pdfmake.github.io/docs/0.1/
*/
export async function downloadPdf(values, payment) {

  const logo = gratefulLogo();

  // link to the signup form with the proposal parameter
  const proposalLink = 'https://' + PROD_URL + '/business?proposal=' + values.proposalId;

  // subscription price to display
  let cost
  if (!payment.useSetPayment) {
    cost = getSubscriptionPrice(values.subscriptionLevel);
  } else {
    cost=payment.amount
  }
  const costFormatted = formatPrice(cost);

  // urlBase is used to determine where to load the font files; assumes that you are running locally if not running on the PROD_URL, would need to modify this in the future if we add other test URLs
  var urlBase;
  if (window.location.host.indexOf(PROD_URL) >= 0) {
    urlBase = 'https://' + PROD_URL;
  } else {
    urlBase = 'http://localhost:3000'
  }

  /*
  pdfmake includes 12 standard font files, but we want to use Inter for the proposal which is not one of their standard fonts
  Inter ttf files are saved in the public/ directory in our React project; to load them, need to point to the correct base URL (note: cannot load remotely when running locally due to a CORS issue)
  */
  const fonts = {
    Inter: {
      normal: urlBase + '/Inter-Regular.ttf',
      bold: urlBase + '/Inter-Bold.ttf',
      italics: urlBase + '/Inter-Regular.ttf',
    }
  }

  // create content for the PDF
  var content = [
    { svg: logo, width: 150, alignment: 'center' },
    { text: 'Proposal for ' + values.name, alignment: 'center', fontSize: 14, bold: true, margin: [0, 24, 0, 0] },
    { text: 'Grateful is a platform where businesses give to nonprofits that matter most to their customers and employees. Businesses use Grateful for:', alignment: 'left', fontSize: 11, margin: [0, 24, 0, 0] },
    {
      ul: [
        'Customer/client appreciation and loyalty',
        'Employee recruitment, retention and recognition',
        'Personalized cause marketing',
      ],
      alignment: 'left',
      fontSize: 11,
      margin: [0, 6, 0, 0] // left, top, right, bottom
    },
    { text: 'Grateful Subscriber:', alignment: 'left', fontSize: 10, color: '#1e1c20', margin: [0, 18, 0, 6] },
    { text: values.name, alignment: 'left', fontSize: 11 },
    { text: values.streetAddress, alignment: 'left', fontSize: 11 },
    { text: values.city + ', ' + values.state + ' ' + values.zipCode, alignment: 'left', fontSize: 11 },
  ];

  // if proposal startDate is tomorrow or later, show start date in the proposal, otherwise skip it
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if ((values.startDate) && (new Date(new Date(values.startDate).toDateString()) >= new Date(tomorrow.toDateString()))) {
    var startDate = String(values.startDate.getMonth() + 1) + '/' + String(values.startDate.getDate()) + '/' + String(values.startDate.getFullYear());
    content.push(
      { text: 'Subscription Start Date:', alignment: 'left', fontSize: 10, color: '#1e1c20', margin: [0, 18, 0, 6] },
      { text: startDate, alignment: 'left', fontSize: 11 },
    );
  }

  content.push(
    { text: 'Annual Subscription Fee:', alignment: 'left', fontSize: 10, color: '#1e1c20', margin: [0, 18, 0, 6] },
    { text: costFormatted, alignment: 'left', fontSize: 11, margin: [0, 0, 0, 18] },
  );

  // add special terms, if any, or add text explaining the giving limits based on the pricing level
  if (values.terms != null) {
    content.push(
      { text: 'Special Terms:', alignment: 'left', fontSize: 10, color: '#1e1c20', margin: [0, 0, 0, 6] },
      { text: values.terms, alignment: 'left', fontSize: 11, margin: [0, 0, 0, 12] },
    );
  } else {
    if (cost === 500) {
      content.push(
        { text: 'Our small business subscription includes up to $2,000 per year in charitable giving for an annual fee of $500. You can upgrade to the next plan if you go over the giving amount.', alignment: 'left', fontSize: 11, margin: [0, 0, 0, 12] },
      );
    } else if (cost === 1500) {
      content.push(
        { text: 'Our medium business subscription includes up to $10,000 per year in charitable giving for an annual fee of $1,500. You can upgrade to the next plan if you go over the giving amount.', alignment: 'left', fontSize: 11, margin: [0, 0, 0, 12] },
      );
    } else if (cost === 3000) {
      content.push(
        { text: 'Our premium subscription includes up to $25,000 per year in charitable giving for an annual fee of $3,000. You can upgrade to an enterprise plan if you go over the giving amount.', alignment: 'left', fontSize: 11, margin: [0, 0, 0, 12] },
      );
    }
  }

  content.push(
    { text: 'All subscriptions include branding Grateful\'s website and emails with your company\'s logo and colors, use of our web, iOS and Android apps, and set up, training and customer support.', alignment: 'left', fontSize: 11, margin: [0, 0, 0, 0] },
    { text: 'To accept this proposal and start your subscription, please use this link:', alignment: 'left', fontSize: 11, margin: [0, 12, 0, 0] },
    { text: proposalLink, link: proposalLink, alignment: 'left', fontSize: 11, decoration: 'underline', color: 'blue' },

    { text: 'Legal Terms:', alignment: 'left', fontSize: 10, color: '#1e1c20', margin: [0, 18, 0, 6] },
    { text: 'Grateful App, LLC is a Delaware public benefit limited liability company. We have applied for certified B Corp status with B Lab. As a B corp, we are a for-profit company with a social mission: connecting nonprofits, businesses and individual donors. Our EIN is 86-3485748.', alignment: 'left', fontSize: 11, margin: [0, 0, 0, 0] },
    { text: 'All charitable dollars shared via Grateful are processed by the Grateful Fund, a charitable giving fund held at the Community Foundation of Greater Des Moines. The Community Foundation is a 501(c)(3) nonprofit and their EIN is 42-6139033.', alignment: 'left', fontSize: 11, margin: [0, 12, 0, 0] },
    { text: [
        'Use of Grateful is governed by our ',
        { text: 'User Agreement', link: 'https://signup.begrateful.org/useragreement', decoration: 'underline' },
        ' and ',
        { text: 'Privacy Policy', link: 'https://www.begrateful.org/privacy', decoration: 'underline' },
        '.'
      ], alignment: 'left', fontSize: 11, margin: [0, 12, 0, 0] 
    },
  );

  // set PDF options
  const options = {
    info: {
      title: 'Grateful Proposal',
    },
    pageSize: 'letter',
    pageOrientation: 'portrait',
    pageMargins: [ 54, 40, 54, 40 ], // left, top, right, bottom; in my testing, a margin of 54 on a letter size page is roughly equal to 0.75 inches
    defaultStyle: {
      font: 'Inter'
    },
    footer: {
      columns: [
        { text: 'begrateful.org', alignment: 'center', link: 'https://begrateful.org', color: '#9e97a4' }
      ]
    },      
    content: content
  };

  // create and download PDF (this is also where the filename is set)
  pdfMake.createPdf(options, null, fonts).download(`Grateful Proposal for ${values.name}.pdf`);

  return;
}
