import { useState, useEffect } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { useFormikContext } from "formik";
import { db } from "../../firebase";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormLabel from "@mui/material/FormLabel";

import PlanSelect from "./PlanSelect";
import PaymentInputs from "./PaymentInputs";
import PaymentMethodRadioGroup from "./PaymentMethodRadioGroup";
import TotalCostImmutableInput from "./TotalCostImmutableInput";
import SpecialTermsImmutableInput from "./SpecialTermsImmutableInput";
import DateInput from "./DateInput";
import TextInput from "./TextInput";
import MuiButton from "./MuiButton";
import LoadingSpinner from "./LoadingSpinner";
import { useStripeContext } from "../../context/stripeContext";
import { usePaymentContext } from "../../context/paymentContext";
import useCreateCompany from "../../hooks/useCreateCompany";

export default function StepTwo({ setCurrentStep }) {
  const { values } = useFormikContext();
  const { useStripe, useElements, CardNumberElement } = useStripeContext();
  const [payment] = usePaymentContext();
  const addCompany = useCreateCompany();
  const [user, setUser] = useState();
  const [stripeError, setStripeError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [dateError, setDateError] = useState();
  const [paymentError, setPaymentError] = useState();
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const createPaymentMethod = async () => {
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    return { error, paymentMethod };
  };

  const submit = async (e) => {
    e.preventDefault();

    var errors = false;

    if (!user && !values.password) {
      setPasswordError("Please create a password");
      errors = true;
    } else if (!{ user } && values.password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      errors = true;
    } else {
      setPasswordError();
    }

    if (!values.startDate) {
      setDateError("Please select a start date");
      errors = true;
      // converting toDateString then back to date removes the time from the date, allowing us to compare dates only
    } else if (
      new Date(new Date(values.startDate).toDateString()) <
      new Date(new Date().toDateString())
    ) {
      setDateError("Start date must be today or in the future");
      errors = true;
    } else {
      setDateError();
    }

    if (!values.paymentMethod) {
      setPaymentError("Please select a payment method");
      errors = true;
    } else {
      setPaymentError();
    }

    if (!errors) {
      if (values.paymentMethod === "payNow") {
        const result = await createPaymentMethod();
        setLoading(true);
        if (result.error) {
          setStripeError(result.error.message);
        } else if (result.paymentMethod) {
          setStripeError();
          await addCompany(values, result.paymentMethod);
        }
      } else {
        setLoading(true);
        await addCompany(values, null);
      }
      setLoading(false);
      setCurrentStep(3);
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("email", "==", values.primaryContactEmail.toLowerCase())
      );
      const snapshot = await getDocs(q);
      let result = false;
      snapshot.forEach((doc) => {
        result = true;
      });
      setUser(result);
    };
    getUser();
  }, [values.primaryContactEmail]);

  return (
    <>
      {loading && <LoadingSpinner displayText="Saving your data..." />}
      {!loading && (
        <>
          {!user && (
            <>
              <FormLabel component="legend">
                {"Create password for " + values.primaryContactEmail + ":"}
              </FormLabel>
              <TextInput name="password" label="" />
              {passwordError && <Alert severity="error">{passwordError}</Alert>}
              <br />
              <br />
            </>
          )}
          <DateInput />
          <Box sx={{ paddingBottom: "20px" }}>
            {dateError && <Alert severity="error">{dateError}</Alert>}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gridGap: 10,
              margin: "25px 0",
            }}
          >
            {values.specialTerms == null && !payment.useSetPayment && (
              <PlanSelect />
            )}
            <PaymentMethodRadioGroup />
            {paymentError && <Alert severity="error">{paymentError}</Alert>}
          </Box>
          {values.paymentMethod === "payNow" && <PaymentInputs />}
          <Box sx={{ paddingBottom: "20px" }}>
            {stripeError && <Alert severity="error">{stripeError}</Alert>}
          </Box>
          <div></div>

          <TotalCostImmutableInput />

          {values.terms != null && <SpecialTermsImmutableInput />}

          <MuiButton onClick={submit}>Save and finish</MuiButton>
        </>
      )}
    </>
  );
}
