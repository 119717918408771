import { useFormikContext } from "formik";


export default function StepThree() {
  const { values } = useFormikContext();

  return (
    <>
		<h2 style={{textAlign: "center"}}>Thank you for your referral!</h2>
    </>
  );
}
