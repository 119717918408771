import { useState } from "react";
import { useFormikContext } from "formik";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";

import { saveStepOneImage } from "../../helpers/saveImage";
import { usePaymentContext } from "../../context/paymentContext";
import ImageUpload from "./ImageUpload";
import MuiButton from "./MuiButton";
import TextInput from "./TextInput";
import EmailInput from "./EmailInput";
import ColorInput from "./ColorInput";
import LoadingSpinner from "./LoadingSpinner";
import { downloadPdf } from './DownloadPdf';
import useCreateCompany from "../../hooks/useCreateCompany";

export default function StepOne({ setCurrentStep }) {
  const { validateForm, values, setValues } = useFormikContext();

  // stepOneSubmitCount is to provide error handling only after clicking go to step 2
  const [stepOneSubmitCount, setStepOneSubmitCount] = useState(0);
  const [emailLogo, setEmailLogo] = useState({ file: null, localUrl: null });
  const [companyLogo, setCompanyLogo] = useState({
    file: null,
    localUrl: null,
  });
  const [emailLogoError, setEmailLogoError] = useState();
  const [companyLogoError, setCompanyLogoError] = useState();
  const [loading, setLoading] = useState(false);
  const [payment] = usePaymentContext();
  const addCompany = useCreateCompany();


  const createPdf = async () => {
    downloadPdf(values, payment);
  }


  return (
    <>
      {loading && <LoadingSpinner displayText="Saving your data..." />}
      {!loading && (
        <>
          <TextInput
            hideError={stepOneSubmitCount === 0}
            name="name"
            label="Business Name"
            required
          />
          <TextInput
            name="location"
            label="Business Location"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="website"
            label="Business Website"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="contactName"
            label="Business Contact Name (if known)"
            hideError={stepOneSubmitCount === 0}
          />
          <TextInput
            name="contactEmail"
            label="Business Contact Email (if known)"
            hideError={stepOneSubmitCount === 0}
          />
          <TextInput
            name="details"
            label="Business Additional Details (if known)"
            hideError={stepOneSubmitCount === 0}
          />
          <TextInput
            name="nonprofit"
            label="Your Nonprofit's Name"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="nonprofitContactName"
            label="Your Name"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="nonprofitContactEmail"
            label="Your Email"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <MuiButton
            onClick={() => {

              setStepOneSubmitCount((count) => count + 1);

              validateForm().then(async (errors) => {
                if (Object.keys(errors).length === 0) {
                  let error = false;

                  if (!error) {
                    setLoading(true);

                    setValues({ ...values });
                      await addCompany(values, null);
                    setLoading(false);
                    setCurrentStep(3);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }
                } else {
                  // scroll to top if errors
                  window.scrollTo({ top: 200, behavior: "smooth" });
                }
              });
            }}
          >
            SAVE
          </MuiButton>
        </>
      )}
    </>
  );
}
