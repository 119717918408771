import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";

import useProposal from "../../hooks/useProposal";
import LoadingSpinner from "./LoadingSpinner";

export default function FormikSetup({ children }) {
  const search = useLocation().search;
  const businessProposalId = new URLSearchParams(search).get("proposal");

  const { loading, company: defaultValues } = useProposal(businessProposalId);

  const validate = (values, props) => {
    const errors = {};

    if (!values.name) {
      errors.name = true;
    }
    if (!values.location) {
      errors.location = true;
    }
    if (!values.website) {
      errors.website = true;
    }
    if (!values.nonprofit) {
      errors.nonprofit = true;
    }
    if (!values.nonprofitContactName) {
      errors.nonprofitContactName = true;
    }

    if (!values.nonprofitContactEmail) {
      errors.nonprofitContactEmail = true;
    }

    return errors;
  };

  return (
    <section className="section overflow-visible">
      {loading && <LoadingSpinner displayText="Saving, please wait..." />}
      {!loading && (
        <Formik
          validate={validate}
          initialValues={defaultValues}
          enableReinitialize={false}
        >
          {() => (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { my: 1 },
              }}
            >
              <div className="container">
                <div className="card overflow-visible bg-white">
                  <div className="card-body">{children}</div>
                </div>
              </div>
            </Box>
          )}
        </Formik>
      )}
    </section>
  );
}
