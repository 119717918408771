import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { storage } from "../firebase";

const saveImageToFirebaseStorage = async (companyName, image) => {
  const now = new Date().toISOString().replace(/:/g, "_");
  const destination_blob_name = `company_logos/${companyName.replace(
    /\s+/g,
    "-"
  )}-${now}-${image.file.name.replace(
    /\s+/g,
    "-"
  )}`;
  const spaceRef = ref(storage, destination_blob_name);

  const snapshot = await uploadBytes(spaceRef, image.file);
  const url = await getDownloadURL(snapshot.ref);
  return url;
};

const saveStepOneImage = async (emailLogo, companyLogo, values) => {
  // save email logo to firebase if user uploaded
  const images = {};

  if (emailLogo.file) {
    images.emailLogo = await saveImageToFirebaseStorage(values.name, emailLogo);
  }

  // save company logo to firebase if user uploaded
  if (companyLogo.file) {
    images.companyLogo = await saveImageToFirebaseStorage(
      values.name,
      companyLogo
    );
  }

  return images;
};

export { saveStepOneImage, saveImageToFirebaseStorage };
