import { GRATEFUL_EMAIL } from "../constants";

const BusinessSignupDescribed = () => {
  return (
    <div className="mt-2 container narrow text-center">
      <div className="h1 mt-2 mb-1 text-primary" style={{fontSize: "3rem"}}>REFER A BUSINESS</div>
      <div className="header-description">
        <div className="p-big">
          <p>
            Suggest a business to participate in Grateful, and we will reach to to them about joining!
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessSignupDescribed;
