import { useFormikContext } from "formik";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";

const PlanRadioGroup = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <FormLabel component="legend">
        Estimated annual amount of charitable giving via Grateful:
      </FormLabel>
      <Select
        value={values.subscriptionLevel}
        onChange={(e) => {
          setFieldValue("subscriptionLevel", e.target.value);
        }}
      >
        <MenuItem value="Small">Less than $2,000</MenuItem>
        <MenuItem value="Medium">$2,001 to $10,000</MenuItem>
        <MenuItem value="Large">$10,001 to $25,000</MenuItem>
      </Select>
      {values.subscriptionLevel === "Small" && (
        <p>
          <br />
          <FormLabel component="legend">Recommended Plan:</FormLabel>
          Our small business subscription includes up to $2,000 per year in
          charitable giving for an annual fee of $500. You can upgrade to the
          next plan if you go over the giving amount.
        </p>
      )}
      {values.subscriptionLevel === "Medium" && (
        <p>
          <br />
          <FormLabel component="legend">Recommended Plan:</FormLabel>
          Our medium business subscription includes up to $10,000 per year in
          charitable giving for an annual fee of $1,500. You can upgrade to the
          next plan if you go over the giving amount.
        </p>
      )}
      {values.subscriptionLevel === "Large" && (
        <p>
          <br />
          <FormLabel component="legend">Recommended Plan:</FormLabel>
          Our premium subscription includes up to $25,000 per year in
          charitable giving for an annual fee of $3,000. You can upgrade to an
          Enterprise plan if you go over the giving amount.
        </p>
      )}
    </>
  );
};

export default PlanRadioGroup;
