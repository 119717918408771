import { useEffect } from "react";
import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

import {
  getSubscriptionPrice,
  formatPrice,
  /* calculateFromTimePeriod, */
} from "../../helpers/getSubscriptionPrices";
import { usePaymentContext } from "../../context/paymentContext";

export default function TotalCostImmutableInput({
  hideError,
  label,
  name,
  ...props
}) {
  const { values, setFieldValue, handleChange } = useFormikContext();
  const [payment] = usePaymentContext();

  let baseCost
  if (!payment.useSetPayment) {
    baseCost = getSubscriptionPrice(values.subscriptionLevel);
  } else {
    baseCost=payment.amount
  }

  /* previously it was showing a monthly amount, then calculating a discount if paid annually */
  /* removed this option when we switched to annual payments for everyone but leaving the code in case we need it in the future */
  /* const actualCost = calculateFromTimePeriod(baseCost, values.timePeriod); */
  const actualCost = baseCost;
  const costFormatted = formatPrice(actualCost);
  
  useEffect(() => {
    setFieldValue("amount", actualCost);
  }, [setFieldValue, actualCost]);

  const fontColor = {
    style: {
      color: "rgba(0, 0, 0, 1)",
      WebkitTextFillColor: "rgba(0, 0, 0, 1)",
    },
  };

  return (
    <>
      <FormLabel component="legend">Total Cost:</FormLabel>
      <TextField
        // dasherizes label
        id="total-cost-input"
        sx={{
          "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
          },
        }}
        fullWidth
        value={costFormatted}
        InputLabelProps={{ shrink: true }}
        disabled
        name={name}
        onChange={handleChange}
        variant="outlined"
        inputProps={fontColor}
        {...props}
      />
    </>
  );
}
